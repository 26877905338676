<template><PrimeDivider /></template>

<script>
import PrimeDivider from "primevue/divider";

export default {
  name: "Divider",
  components: {
    PrimeDivider,
  },
};
</script>
