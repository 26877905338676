import movieService from "@/services/movieService.js";

export const namespaced = true;

export const state = {
  moviesResults: [],
};

export const getters = {};

export const mutations = {
  SET_MOVIES(state, movies) {
    state.moviesResults = movies;
    console.log("set_movies:", state.moviesResults);
  },
};

export const actions = {
  searchMovies({ commit, dispatch }) {
    const params = {
      apiKey: "5a6c707c5e04d9bbb0450d957bfb3d84",
      language: "pt-BR",
      searchName: "Guardi",
    };
    return movieService
      .searchMovie(params)
      .then(({ data }) => {
        console.log("data:", data.results);
        commit("SET_MOVIES", data.results);
      })
      .catch(({ response }) => {
        dispatch("notify/error", response.data);
      })
      .finally(() => {});
  },
};
