<template>
  <PrimeButton />
</template>

<script>
import PrimeButton from "primevue/button";

export default {
  name: "Divider",
  components: {
    PrimeButton,
  },
};
</script>
