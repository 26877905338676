<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <Divider class="border-t-2" />
      <h1 class="p-2 text-5xl">
        Movies
        <i class="pi pi-video" style="font-size: 2rem"></i>
      </h1>
      <Divider class="border-b-2" />
    </div>

    <div
      class="container bg-gray-50 mx-auto grid grid-cols-1 gap-y-4 w-screen mt-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <div
        class="shadow-xl w-[320px] mx-auto"
        v-for="movie in moviesResults"
        :key="movie.id"
      >
        <div class="flex flex-wrap mx-auto">
          <div class="mx-auto my-auto h-[433px] w-[320px]">
            <img
              :src="`https://image.tmdb.org/t/p/w500${movie.poster_path}`"
              :alt="movie.poster_path"
            />
          </div>
          <div class="mx-4 mt-12">
            <Divider class="border-t-2" />
            <h2 class="text-center w-full p-2 h-15 max-h-35">
              {{ movie.original_title }} ({{ date(movie.release_date) }})
            </h2>
            <Divider class="border-b-2" />
            <p class="sm:h-48 indent-4 text-clip overflow-hidden ...">
              {{ movie.overview }}
            </p>
          </div>
          <div class="flex flex-row-reverse grow border-2 self-end items-end">
            <Button
              class="p-4 m-2 p-button-success max-h-16 border-2 rounded-xl bg-amber-500 hover:text-white shadow-md hover:shadow-amber-200"
              label="Ver mais..."
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Divider from "@/components/Divider";
import Button from "@/components/Button";
export default {
  name: "MovieMain",
  components: {
    Divider,
    Button,
  },
  created() {
    this.searchMovies();
  },
  computed: {
    ...mapState("movie", ["moviesResults"]),
  },
  methods: {
    ...mapActions("movie", ["searchMovies"]),
    date(stringDate) {
      var parts = stringDate.split("-");
      return parts[0];
    },
  },
};
</script>

<style></style>
